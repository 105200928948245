<template>
  <div>
    <!-- 表单 -->
    <div class="facilityBox">
      <!-- form表单 -->
      <el-form
        class="manageForm"
        :model="manageForm"
        :inline="true"
        label-width="85px"
      >
        <el-form-item label="企业名称：" prop="EnterpriseFullName">
          <el-input
            class="fromInp"
            v-model="manageForm.EnterpriseFullName"
            placeholder="输入企业名称"
            @keyup.enter.native="search()"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="手机号："
          prop="LegalPersonlPhone"
          label-width="75px"
        >
          <el-input
            class="fromInp"
            v-model="manageForm.LegalPersonlPhone"
            placeholder="输入手机号"
            @keyup.enter.native="search()"
          ></el-input>
        </el-form-item>
        <el-form-item label="提交日期：" prop="TaskDatetime">
          <el-date-picker
            v-model="manageForm.TaskDatetime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd"
            end-placeholder="结束日期"
            @change="searchChange()"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button
            type="primary"
            size="medium"
            @click="search()"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button
            type="primary"
            size="medium"
            @click="resetForm()"
            icon="el-icon-delete"
            >清空</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
      >
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseFullName"
          label="企业名称"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseCreditCode"
          label="企业统一社会信用代码"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseAddress"
          label="企业地址"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="SubmitDatetime"
          label="提交时间"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Status"
          label="状态"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.Status != '审核未通过' ? 'warning' : 'danger'"
              >{{ scope.row.Status }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="Remark"
          label="失败原因"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="120">
          <template slot-scope="scope">
            <el-button
              v-if="!searchStatus"
              type="primary"
              size="small"
              @click="goFacility(scope.row, pagination.page)"
              :icon="
                scope.row.Status == '审核未通过'
                  ? 'el-icon-document'
                  : 'el-icon-notebook-2'
              "
            >
              {{ scope.row.Status == "审核未通过" ? "查看详情" : "审核" }}
            </el-button>
            <el-button
              v-else
              type="primary"
              size="small"
              @click="goFacility1(scope.row, pagination.page)"
              icon="el-icon-document"
            >
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <!-- 分页 -->
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { GetEnterprise } from "@/api/auditInfo/auditOwner/index";
import certifiedOwner from "@/views/platform/auditInfo/certifiedOwner/index";
export default {
  data() {
    return {
      // 分页参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 列表数据
      tableData: [],
      // 筛选条件数据
      manageForm: {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
      },
      loading: true,
    };
  },
  props: ["searchStatus"],
  activated() {
    // 获取列表数据
    this.GetEnterprise();
  },
  created() {
    // 获取列表
    // this.GetEnterprise();
  },
  methods: {
    searchChange() {
      this.search();
    },
    // 清空搜索条件
    resetForm() {
      this.loading = true;
      this.manageForm = {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
      };
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        RoleType: 2,
        Status: this.searchStatus ? 100 : 101,
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
      };
      GetEnterprise({ Json: JSON.stringify(data) })
        .then((res) => {
          this.tableData = res.enterpriseBase;
          this.pagination.total = Number(res.totalRowCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 搜索
    search() {
      this.loading = true;
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        RoleType: 2,
        Status: this.searchStatus ? 100 : 101,
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
      };
      GetEnterprise({ Json: JSON.stringify(data) })
        .then((res) => {
          this.tableData = res.enterpriseBase;
          this.pagination.total = Number(res.totalRowCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.page = e;
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        RoleType: 2,
        Status: this.searchStatus ? 100 : 101,
        pageSize: this.pagination.pagesize,
        pageIndex: e,
      };
      GetEnterprise({ Json: JSON.stringify(data) })
        .then((res) => {
          this.tableData = res.enterpriseBase;
          this.pagination.total = Number(res.totalRowCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 查询列表（货主，服务商）列表
    GetEnterprise() {
      this.loading = true;
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        RoleType: 2,
        Status: this.searchStatus ? 100 : 101,
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
      };
      GetEnterprise({ Json: JSON.stringify(data) })
        .then((res) => {
          this.tableData = res.enterpriseBase;
          this.pagination.total = Number(res.totalRowCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 查看详情
    goFacility(item, pageIndex) {
      this.$router.push({
        path: "/auditInfo/auditOwner/ownerInfo",
        query: { UserID: item.UserID, pageIndex },
      });
    },

    //  审核失败 - 跳转详情
    goFacility1(item, pageIndex) {
      this.$router.push({
        path: "/auditInfo/auditOwner/certifiedInfo",
        query: { UserID: item.UserID, pageIndex },
      });
    },
  },
  components: {
    certifiedOwner,
  },
};
</script>

<style lang="scss" scoped></style>
